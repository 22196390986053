<template>
    <header class="header-global">
        <b-navbar toggleable="lg" type="dark" variant="dark" class="fixed-top">
            <b-navbar-toggle target="nav-collapse" />

            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav class="menu">
                    <b-nav-item><router-link to="/" class="nav-link">Главная</router-link></b-nav-item>
                    <b-nav-item><router-link to="/material-orchestra" class="nav-link">Духовные Ноты</router-link></b-nav-item>
                    <b-nav-item><router-link to="/material-choir" class="nav-link">Хоровые Ноты</router-link></b-nav-item>
                    <b-nav-item><router-link to="/material-learning" class="nav-link">Учебный Материал</router-link></b-nav-item>
                    <b-nav-item><router-link to="/photos" class="nav-link">Фото</router-link></b-nav-item>
                    <!--<b-nav-item><router-link to="/orchestras" class="nav-link">Оркестры</router-link></b-nav-item>-->
                    <b-nav-item><router-link to="/prazdniktrub" class="nav-link">Праздник труб</router-link></b-nav-item>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
        <hero />
    </header>
</template>
<script>
import Hero from "./components/Hero";

export default {
    name: "AppHeader",
    components: {
        Hero
    }
};
</script>
